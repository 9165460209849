import toastr from 'toastr';

let debug = true;

toastr.options = {
    "closeButton": true,
    "debug": debug,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-bottom-center",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "10000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
  }

  export function displayMessage(message,toast_type,toast_title) {
    if (!toast_type || (toast_type!=="success" && toast_type!=="info" && toast_type!=="warning" && toast_type!=="error")) {
        toast_type = "info";
    }
    if (!toast_title) {
        toast_title = "";
    }
    toastr[toast_type](message, toast_title);
    //console.log(message);
}